import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import _ from 'lodash';

import App from '@/components/App';
import Head from '@/components/Head';
import PageMainContent from '@/components/PageMainContent';
import PressRollListWithFilter from '@/components/PressRollListWithFilter';
import PageHeader from '@/components/PageHeader';
import FeaturePost from '@/components/FeaturePost';
import trackPage from '@aa/trackPage';
import SAMMap from '@aa/SAMMap';
import pressHeroImage from '@/img/press-hero.jpg';

const ENABLE_FEATURE_POST = false;

const PressIndexPageLayout = ({ pageContext: { data } }) => {
  const intl = useIntl();
  const { locale: language } = intl;

  const posts = (_.get(data, 'data.allMarkdownRemark.edges') || []).filter(post => _.get(post, 'node.frontmatter.hidden') !== true);
  const featurePost  = posts[0] || null;
  const featurePostProps = featurePost ? {
    post: featurePost,
    language,
    localizedContent: _.get(featurePost, 'node.frontmatter.languages.content') || {}
  } : {};

  trackPage({
    pageId: SAMMap[language].pressList.pageId,
    ...SAMMap[language].common
  });

  return (
    <>
      { ENABLE_FEATURE_POST && featurePost && (
        <FeaturePost {...featurePostProps} />
      )}
      <PageMainContent>
        <Head title={intl.formatMessage({ id: 'press.pageTitle' })} />
        <PageHeader
          title={intl.formatMessage({ id: 'press.pageTitle' })}
          heroImage={ENABLE_FEATURE_POST ? null : pressHeroImage}
        />
        <PressRollListWithFilter posts={posts} />
      </PageMainContent>
    </>
  );
};

PressIndexPageLayout.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
    defaultLanguage: PropTypes.string,
    data: PropTypes.any,
  }),
};

const Page = ({ pageContext }) => (
  <App language={pageContext.language} defaultLanguage={pageContext.defaultLanguage}>
    <PressIndexPageLayout pageContext={pageContext} />
  </App>
);

Page.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string,
    defaultLanguage: PropTypes.string,
    data: PropTypes.any,
  }),
};

export default Page;
