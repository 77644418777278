import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import SC from './style';

import PressRollList from '@/components/PressRollList';

const years = [];

const PressRollListWithFilter = ({ posts }) => {
  posts.forEach(post => {
    const postYear = post.node.frontmatter.date.substring(0, 4);
    if (!years.includes(postYear)) years.push(postYear);
  });
  const [selectedYear, setSelectedYear] = React.useState(years[0]);

  const filteredPosts = posts.filter(post => {
    const localizedContent = _.get(post, 'node.frontmatter.languages.content') || {};
    const isNotTitleEmpty = !!localizedContent.title;
    const postYear = post.node.frontmatter.date.substring(0, 4);
    const isMatchSelectedYear = postYear === selectedYear;
    return isNotTitleEmpty && isMatchSelectedYear;
  });

  return (
    <>
      <SC.FilterResultMessage>
        <FormattedMessage
          id="press.filter.result"
          values={{ itemCount: filteredPosts.length }}
        />
      </SC.FilterResultMessage>
      <SC.FilterContainer>
        <SC.FilterBy className="is-size-5 has-text-weight-bold">
          <FormattedMessage id="press.filter.filterBy" />
        </SC.FilterBy>
        <SC.Select
          defaultValue={selectedYear}
          onChange={({ target: { value }}) => setSelectedYear(value)}>
          {years.sort((a, b) => {
            return Number(b) - Number(a);
          }).map(year => <option key={year} value={year}>{year}</option>)}
        </SC.Select>
      </SC.FilterContainer>
      <PressRollList posts={filteredPosts} />
    </>
  );
};

PressRollListWithFilter.propTypes = {
  posts: PropTypes.array,
};

export default PressRollListWithFilter;
