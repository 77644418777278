import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Styled = {};

Styled.FeaturePostContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding: 40px 20px;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(3px);
    background-image: url(${props => props.background});
    background-position: center center;
    background-size: cover;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #4cd8b0, #43a4c5);
    opacity: .8;
  }
`;

Styled.FeaturePost = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  max-width: 940px;
  margin: 0 auto;
  flex-direction: column;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: ${props => props.theme.color.white};
  }

  @media ${props => props.theme.device.above.tablet} {
    flex-direction: row;
  }
`;

Styled.GalleryContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${props => props.theme.device.above.tablet} {
    width: 66.6%;
  }

  img {
    display: block;
    margin: 0 auto;
    border-radius: 15px;
  }
`;

Styled.FeatureInfo = styled.div`
  order: 2;
  width: 100%;
  margin-top: 20px;
  text-align: center;

  @media ${props => props.theme.device.above.tablet} {
    order: -1;
    width: 33.3%;
    margin-top: 0;
    margin-right: 20px;
    text-align: left;
  }
`;

Styled.PostTitle = styled.h2`
  margin: 1rem 0;
  font-size: ${props => props.theme.font.size2};

  @media ${props => props.theme.device.below.tablet} {
    font-size: ${props => props.theme.font.size3};
  }
`;

Styled.DescriptionContent = styled.div`
  margin: 1rem 0;
  display: block;
`;

Styled.ReadMoreButton = styled(props => <Link {...props} />)`
  display: inline-block;
  border: 2px solid ${props => props.theme.color.white};
  border-radius: 6px;
  color: ${props => props.theme.color.white};
  letter-spacing: .85px;
  margin-top: 24px;
  padding: 15px 30px 14px;
  text-transform: uppercase;
  transition: all .4s ease-in-out;

  &:hover,
  &:active {
    background: ${props => props.theme.color.white};
    color: ${props => props.theme.color.primary};
  }

  &:active {
    border-color: ${props => props.theme.color.primary};
  }
`;

export default Styled;
