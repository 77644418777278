import styled from 'styled-components';

const Styled = {};

Styled.FilterResultMessage = styled.div`
  border-top: 1px solid ${props => props.theme.color.borderLight};
  border-bottom: 1px solid ${props => props.theme.color.borderLight};
  padding: 20px;
`;

Styled.FilterContainer = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 10px 20px;
  margin: 0;
  background: linear-gradient(to bottom, #feffff, #ebf6fc);
`;

Styled.FilterBy = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
`;

Styled.Select = styled.select`
  display: flex;
  align-items: center;
  width: 160px;
  height: 100%;
  padding: 0 16px;
  margin: 0;
  border: 0;
  border-left: 1px solid ${props => props.theme.color.borderLight};
  border-right: 1px solid ${props => props.theme.color.borderLight};
  background: transparent;

  &:hover,
  &:active,
  &:focus {
    color: ${props => props.theme.color.link};
  }
`;

export default Styled;
